import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid';
import CustomSearch from '../base/Textfield/CustomSearch';
import InputAdornment from '@mui/material/InputAdornment';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import { StyledTableBody, StyledTableCell } from '../base/Table/CustomTable';
import TableContainer from '@mui/material/TableContainer';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Pagination from '../base/Pagination/Pagination';
import SearchIcon from '@mui/icons-material/Search';
import Checkbox from '@mui/material/Checkbox';
import { CheckedIcon, Icon, IndeterminateIcon } from '../base/Checkbox/CustomCheckbox';
import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from '@mui/material/Select';
import CustomSelect from '../base/Select/CustomSelect';
import Button from '../base/Button/Button';
import OutlinedInput from '@mui/material/OutlinedInput';
import styles from './Guestlist.module.css'
import { Order, guest } from '../../type';
import { getComparator, stableSort, csvmaker, downloadCsv } from '../../constants';
import Toast from '../base/Toast/Toast';

interface RSVPTableProps {
  data: guest[]
}

interface TableHeadProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
  onRequestSearch: (value: string) => void;
  handleActionSubmit: (action: string) => void
  handleDownload: (x: string) => void
}

const actions = [
  'Download',
]

function TableCustomHead(props: TableHeadProps) {
  const { onSelectAllClick, onRequestSearch, handleActionSubmit, handleDownload, numSelected, rowCount } = props;
  const [action, setAction] = useState('Download');

  const handleActionChange = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;
    setAction(value);
  };

  const requestSearch = (event: any) => {
    onRequestSearch(event.target.value)

  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" sx={{ paddingLeft: 0, borderBottom: 0 }}>
          <Checkbox
            icon={<Icon />}
            checkedIcon={<CheckedIcon />}
            indeterminateIcon={<IndeterminateIcon />}
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        <TableCell colSpan={7} sx={{ borderBottom: 0 }}>
          <Grid container spacing={2} justifyContent={'space-between'}>
            <Grid item xs={6} md={6}>
              <span style={{ fontWeight: 400 }}>Action:</span>
              <CustomSelect
                sx={{ width: { xs: '150px', sm: '250px' }, mx: 2 }}
                className={styles.input}
                size='small'
                labelId="action"
                value={action}
                MenuProps={{
                  disableScrollLock: true,
                }}
                onChange={handleActionChange}
                input={<OutlinedInput />}
              >
                {actions.map((item) => (
                  <MenuItem
                    key={item}
                    value={item}
                    sx={{
                      '&.Mui-selected': {
                        backgroundColor: 'var(--TLC-web-grey)',
                        '&:hover': {
                          backgroundColor: 'var(--TLC-web-grey)',
                        },
                      },
                    }}
                  >
                    {item}
                  </MenuItem>
                ))}
              </CustomSelect>
              <Button className='btnTransparent' sx={{ my: 0, width: { xs: '150px', sm: '200px' } }} onClick={() => handleActionSubmit(action)}>Submit</Button>
            </Grid>

            <Grid item >
              <CustomSearch
                placeholder='Search'
                sx={{ width: { xs: '150px', sm: '300px' }, mr: 2 }}
                onChange={requestSearch}
                className={styles.input}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon style={{ color: 'var(--TLC-web-grey)' }} />
                    </InputAdornment>
                  ),
                }}
              />
              <Button className='btnTransparent' sx={{ my: 0, width: { xs: '150px', sm: '200px' }, ml: 2 }} onClick={() => handleDownload('All')}>Download List</Button>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

const RSVPTable: React.FC<RSVPTableProps> = (props) => {
  const { data } = props
  const [page, setPage] = useState(0);
  const [query, setQuery] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState('type')
  const [filtered, setFiltered] = useState<any[]>([]);
  const [selected, setSelected] = useState<any[]>([]);
  //Edit guest
  const [toast, setToast] = useState({
    message: '',
    type: 'success',
    open: false,
    handleClose: () => { setToast(prev => ({ ...prev, open: false })) }
  })

  useEffect(() => {
    setFiltered(data)
  }, [data])

  const isSelected = (id: string | number | undefined) => {
    return selected.map(x => x.id).indexOf(id) !== -1
  }

  const filteredItems = data.filter((item) => {
    let str = item.firstName + ' ' + item.lastName
    return str.toLowerCase().includes(query.toLowerCase())
  })

  const handleSearch = (value: string) => {
    setQuery(value)
    setPage(0)
  }

  //Sort
  const handleRequestSort = (
    property: keyof guest,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  //All checkbox
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = filtered;
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  //Individual checkbox
  const handleClick = (event: React.MouseEvent<unknown>, row: any) => {
    const selectedIndex = selected.map(x => x.id).indexOf(row.id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected.push(...selected, row)
    }
    else if (selectedIndex === 0) {
      newSelected = selected.slice(1)
    }
    else if (selectedIndex === selected.length - 1) {
      newSelected = selected.slice(0, -1)
    }
    else if (selectedIndex > 0) {
      let array1 = selected.slice(0, selectedIndex)
      let array2 = selected.slice(selectedIndex + 1)
      newSelected = array1.concat(array2)
    }
    setSelected(newSelected)
  };

  const handleActionSubmit = async (action: string) => {
    try {
      if (selected.length === 0) { return }
      let rv = { message: '' }
      let body = {
        guestlist: selected
      }
      if (action === 'Download') {
        rv = handleDownload('selected')
      }

      if (rv) {
        setToast({
          ...toast,
          message: rv?.message,
          open: true,
          type: 'success'
        })
        setSelected([])
      }
    } catch (e: any) {
      setToast({
        ...toast,
        message: e.toString(),
        open: true,
        type: 'error'
      })
    }
  }

  //Pagination
  const totalPages = Math.ceil(filteredItems.length / rowsPerPage)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (rpp: number) => {
    setRowsPerPage(rpp);
    setPage(0);
  };

  const handleDownload = (x: string) => {
    let data = []
    let array = []
    let filename = ''
    x == 'All' ? data = filtered : data = selected

    for (var row of data) {
      let insert = {
        ...row,
        dateAttending: row.dateAttending ? `"${row.dateAttending.replace(',', ', ')}"` : '-'
      }
      array.push(insert)
    }
    filename = 'rsvp'

    let csv = csvmaker(array)
    let rv = downloadCsv(csv, filename)

    if (rv) {
      setSelected([])
    }
    return rv
  }

  return (
    <>
      <Toast {...toast} />
      <TableContainer>
        <Table>
          <TableCustomHead
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            onRequestSearch={handleSearch}
            handleActionSubmit={handleActionSubmit}
            handleDownload={handleDownload}
            rowCount={filtered.length}
          />
          <TableHead>
            <TableRow>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell sx={{ width: { xs: 200 } }}>
                <TableSortLabel
                  className={styles.tableheader}
                  active={orderBy === 'firstName'}
                  direction={orderBy === 'firstName' ? order : 'asc'}
                  onClick={() => handleRequestSort('firstName')}
                  sx={{ paddingLeft: 0 }}
                >Name
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell sx={{ width: { xs: 200 } }}>
                <TableSortLabel
                  className={styles.tableheader}
                  active={orderBy === 'email'}
                  direction={orderBy === 'email' ? order : 'asc'}
                  onClick={() => handleRequestSort('email')}>
                  Email
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell sx={{ width: { xs: 150 } }} className={styles.tableheader}>Phone Number</StyledTableCell>
              <StyledTableCell sx={{ width: { xs: 100 } }} className={styles.tableheader}>RSVP Attending</StyledTableCell>
              <StyledTableCell sx={{ width: { xs: 100 } }} className={styles.tableheader}>RSVP Date</StyledTableCell>

            </TableRow>
          </TableHead>
          <StyledTableBody>
            {stableSort(filteredItems, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `eventlist-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    <TableCell sx={{ padding: 0 }}>
                      <Checkbox
                        icon={<Icon />}
                        checkedIcon={<CheckedIcon />}
                        onClick={(event) => handleClick(event, row)}
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell sx={{ width: { xs: 200, sm: 300 } }}>{row.firstName} {row.lastName}</TableCell>
                    <TableCell sx={{ width: { xs: 200, sm: 300 } }}>{row.email}</TableCell>
                    <TableCell
                      sx={{ width: { xs: 200, sm: 500 } }}
                    >
                      +{row.phoneNumber}
                    </TableCell>
                    <TableCell sx={{ width: { xs: 200, sm: 500 } }}>{row.attending === 1 ? 'Yes' : 'No'}</TableCell>
                    <TableCell sx={{ width: { xs: 200, sm: 500 } }}>{row.dateAttending ? `${(row.dateAttending as string).replace(',', ', ')}` : '-'}</TableCell>
                  </TableRow>
                )
              })
            }
          </StyledTableBody>
        </Table>
        <Pagination
          sx={{ mt: 3 }}
          variant="outlined" shape="rounded"
          count={totalPages}
          page={page + 1}
          onChange={handleChangePage}
          handleViewChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  )
}

export default RSVPTable