import React, { useState, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import Box from '@mui/material/Box';
import MuiLink from '@mui/material/Link/Link';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import CustomTextField from '../../base/Textfield/CustomTextField';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { SelectChangeEvent } from '@mui/material/Select';
import CustomSelect from '../../base/Select/CustomSelect';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import LocationIcon from '../../base/Icons/LocationIcon';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import VideoIcon from '../../base/Icons/VideoIcon';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import styles from './CreateEvent.module.css'
import Button from '../../base/Button/Button';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { addNewEvent, updateEvent } from '../../../features/account/accountSlice';
import { setEvent } from '../../../features/event/eventSlice';
import { useAddEventMutation } from '../../../features/account/accountRestApi';
import { useGetSpecificGuestlistQuery, useUpdateEventGuestMutation, useBulkAddGuestMutation } from '../../../features/guestlist/guestlistRestApi';
import Masterlist from '../../Guestlist/Masterlist';
import { eventsList_1, eventsList_2, eventsList_3, livestreamAction, validatePostal } from '../../../constants';
import { getAccountState } from '../../../features/account/accountSlice';
import { eventDetails, guest } from '../../../type';
import GuestForm from '../../Guestlist/GuestForm';
import Toast from '../../base/Toast/Toast';
import { TableContainer, TableRow } from '@mui/material';
import { StyledTableBody, StyledTableCell } from '../../base/Table/CustomTable';
import 'dayjs/locale/en-gb';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';


interface CreateEventProps {
  index: any
  type: string
  eventDetails: eventDetails
  funeralId: any
}

const CreateEvent: React.FC<CreateEventProps> = (props) => {
  const dispatch = useAppDispatch()
  const accountState = useAppSelector(getAccountState)
  const [loading, setLoading] = useState(false)
  const { index, eventDetails, funeralId } = props
  const [eventInfo, setEventInfo] = useState<any>({
    type: 'Wake Funeral',
    title: '',
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
    address: '',
    livestream: 'Live Stream Not Available',
    livestreamUrl: '',
    details: '',
  })
  const [address, setAddress] = useState({
    addline1: "",
    addline2: "",
    addpostalCode: ""
  })
  const [errors, setErrors] = useState({
    tyle: false,
    title: false,
    startDate: false,
    startTime: false,
    address: false,
    details: false,
    livestreamUrl: false
  })
  const [selectedEventType, setSelectedEventType] = useState<any>({})
  const [eventTypes, setEventTypes] = useState<any[]>(eventsList_1)
  const [guestList, setGuestList] = useState<any[]>([])
  const [showMasterList, toggleMasterlist] = useState(false)
  const [startDate, setStartDate] = useState<Dayjs | null>(null)
  const [endDate, setEndDate] = useState<Dayjs | null>(null)
  // const [startTime, setStartTime] = useState<Dayjs | null>(null)
  const [startTime, setStartTime] = useState<String | null>(null)
  // const [endTime, setEndTime] = useState<Dayjs | null>(null)
  const [endTime, setEndTime] = useState<String | null>(null)
  const [toast, setToast] = useState({
    message: '',
    type: 'success',
    open: false,
    handleClose: () => { setToast(prev => ({ ...prev, open: false })) }
  })

  const [addEvent] = useAddEventMutation()
  const [updateEventGuest] = useUpdateEventGuestMutation()
  const [bulkAddGuest] = useBulkAddGuestMutation()

  useEffect(() => {
    const { religion } = accountState.details
    if (['Budhhist', 'Taoism', 'Free-Thinker'].includes(religion)) {
      setEventTypes(eventsList_1)
    }
    if (['Christian Catholic', 'Roman Catholic'].includes(religion)) {
      setEventTypes(eventsList_2)
    }
    if (['Straight', 'Soka'].includes(religion)) {
      setEventTypes(eventsList_3)
    }
  }, [accountState])


  useEffect(() => {
    if (!eventDetails.id) {
      setEventInfo({
        ...eventDetails,
        startDate: dayjs().format('MM/DD/YYYY'),
        startTime: dayjs().format('HH:mm')
      })
      setStartDate(dayjs())
      const timeNow = dayjs().format('HH:mm')
      setStartTime(timeNow)
    } else {
      setEventInfo({
        ...eventDetails,
      })
      setStartDate(dayjs(eventDetails.startDate))
      setStartTime(eventDetails.startTime)
      let addArr = eventDetails.address.split(',')
      setAddress({
        addline1: addArr[0]?.trim(),
        addline2: addArr[1]?.trim(),
        addpostalCode: addArr[2]?.trim(),
      })
    }
    if (eventDetails.endDate) {
      setEndDate(dayjs(eventDetails.endDate))
    }
    if (eventDetails.endTime) {
      setEndTime(eventDetails.endTime)
    }
    let found: any = eventTypes.find((item) => {
      return item.type === eventDetails.type
    })
    if (found) {
      setSelectedEventType(found)
    }


  }, [eventDetails])

  const { data, isLoading } = useGetSpecificGuestlistQuery({ eventId: eventInfo.id, funeralId: funeralId }, { refetchOnMountOrArgChange: true })

  useEffect(() => {
    if (data && !isLoading) {
      setGuestList(data.invites)
    }
  }, [data, isLoading])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let key = event.target.id
    let value = event.target.value

    setEventInfo({
      ...eventInfo,
      [key]: value
    })

  }
  const handleTypeChange = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;
    setEventInfo({
      ...eventInfo,
      type: value
    })
    let found: any = eventTypes.find((item) => {
      return item.type === value
    })
    if (found) {
      setSelectedEventType(found)
    }
  }
  const handleLivestreamChange = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;

    setEventInfo({
      ...eventInfo,
      livestream: value
    })
  }

  const handleShowMasterList = () => {
    let y = window.scrollY;
    let elem = document.getElementById(`event_${index}`)
    if (elem) {
      let pos = elem.getBoundingClientRect()

      window.scrollTo(0, pos.top + y - 100)
    }

    toggleMasterlist(!showMasterList)


  }

  const handleAddGuest = async (guest: guest) => {
    let found = guestList.find((item) => {
      return item.email === guest.email
    })

    if (!found) {
      let newlist = [...guestList, guest]
      setGuestList(newlist)
      //Add to db if event is created
      if (eventInfo.id) {
        let rv = await updateEventGuest({ type: 'add', funeralId: props.funeralId, eventId: eventInfo.id, guest: guest }).unwrap()
        if (rv) {
          setToast({
            ...toast,
            open: true,
            message: rv.message,
            type: 'success'
          })
          return true
        }
      } else {
        return true
      }
    }

  }

  const handleGuestDelete = (item: any) => {
    let newlist = guestList.filter((guest) => guest.email !== item.email)
    setGuestList(newlist)

    if (eventInfo.id) {
      updateEventGuest({ type: 'delete', funeralId: props.funeralId, eventId: eventInfo.id, guest: item })
    }
  }

  const handleSaveEvent = async (event: React.FormEvent) => {
    const hasError = validation()
    if (hasError) {
      setToast({
        ...toast,
        message: 'Please fill up all required inputs!',
        type: "error",
        open: true
      })
      return
    }
    try {
      setLoading(true)
      let addressString = await addAddress()
      let body = {
        funeralId: props.funeralId,
        eventInfo: { ...eventInfo, address: addressString },
        guestList: guestList,
        selectedEventType: selectedEventType
      }
      let rv = await addEvent(body).unwrap()
      if (rv) {
        setToast({
          ...toast,
          message: rv.message,
          type: "success",
          open: true
        })

        if (props.type === 'account') {
          const { message, ...rest } = rv
          if (rv.message.includes('created')) {
            dispatch(addNewEvent(rest))
          }
          if (rv.message.includes('updated')) {
            dispatch(updateEvent(eventInfo))
          }
        } else {
          const { message, ...rest } = rv
          dispatch(setEvent(rest))
        }
      }
      setLoading(false)
      return
    } catch (e: any) {
      setLoading(false)
      setToast({
        ...toast,
        message: e?.data?.message || e.message,
        type: "error",
        open: true
      })
    }

  }

  const saveGuestlist = (list: any) => {
    let newlist: any = []
    for (var item of list) {
      let found = guestList.find((row) => {
        return row.email === item.email
      })

      if (!found) {
        newlist.push(item)
      }
    }

    if (eventInfo.id) {
      setGuestList(prev => ([
        ...prev,
        ...newlist
      ]))
      bulkAddGuest({ funeralId: funeralId, eventId: eventInfo.id, list: newlist })
    } else {
      setGuestList(prev => ([
        ...prev,
        ...newlist
      ]))
    }
    toggleMasterlist(!showMasterList)
  }

  const handleDateChange = (key: string, value: any) => {
    const dateStr = dayjs(value).format('MM/DD/YYYY')
    setEventInfo({
      ...eventInfo,
      [key]: dateStr
    })
    if (key === 'startDate') {
      setStartDate(value)
    } else {
      setEndDate(value)
    }
  }

  const handleTimeChange = (key: string, value: any) => {
    setEventInfo({
      ...eventInfo,
      [key]: value
    })
    if (key === 'startTime') {
      setStartTime(value)
    } else {
      setEndTime(value)
    }
  }

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let key = event.target.id
    let value = event.target.value
    if (key === 'addpostalCode') {
      let regex = /^\d*$/
      if (!regex.test(value.trim())) {
        return
      } else {
        setAddress({
          ...address,
          [key]: value
        })
        return
      }
    }

    setAddress({
      ...address,
      [key]: value
    })

  }

  const addAddress = async () => {
    if (address.addline1 || address.addline2 || address.addpostalCode) {
      let rv = validatePostal(address.addpostalCode)
      if (!rv) {
        setToast({
          ...toast,
          open: true,
          type: 'error',
          message: "Please enter a valid Postal Code!"
        })
        throw new Error('Please enter a valid Postal Code!')
      } else {
        setToast({
          ...toast,
          open: false,
          message: "Please enter a valid address!",
        })
      }
    }
    let str: string = ''
    if (address.addline1) {
      str = address.addline1
    }
    if (address.addline2) {
      str += ', ' + address.addline2
    }
    if (address.addpostalCode) {
      str += ', ' + address.addpostalCode
    }
    return str.trim()
  }

  const convertTime = (time: string) => {
    const arr = time.split(':')
    return dayjs().hour(Number(arr[0])).minute(Number(arr[1]))
  }

  const validation = () => {
    let hasError = false
    for (const [key, value] of Object.entries(eventInfo)) {
      switch (key) {
        case "title":
          if (!value) {
            setErrors(prev => ({ ...prev, [key]: true }))
            hasError = true
          } else {
            setErrors(prev => ({ ...prev, [key]: false }))
          }
          break;
        case "startDate":
          if (!value) {
            setErrors(prev => ({ ...prev, [key]: true }))
            hasError = true
          } else {
            setErrors(prev => ({ ...prev, [key]: false }))
          }
          break;
        case "startTime":
          if (!value) {
            setErrors(prev => ({ ...prev, [key]: true }))
            hasError = true
          } else {
            setErrors(prev => ({ ...prev, [key]: false }))
          }
          break;
        case "address":
          if (!address.addline1 || !address.addline2 || !address.addpostalCode) {
            setErrors(prev => ({ ...prev, [key]: true }))
            hasError = true
          } else {
            setErrors(prev => ({ ...prev, [key]: false }))
          }
          break;
        case "details":
          if (!value) {
            setErrors(prev => ({ ...prev, [key]: true }))
            hasError = true
          } else {
            setErrors(prev => ({ ...prev, [key]: false }))
          }
          break;
        case "livestreamUrl":
          if (!value && eventInfo.livestream === 'Live Stream Available') {
            setErrors(prev => ({ ...prev, [key]: true }))
            hasError = true
          } else {
            setErrors(prev => ({ ...prev, [key]: false }))
          }
          break;
        default:
          break;
      }
    }

    return hasError
  }

  /*  if(showMasterList) {
     return (
       <Masterlist onSave={saveGuestlist} toggleMasterlist={toggleMasterlist}/>
     )
   }else { */

  return (
    <Box id={`event_${index}`} className={styles.container} sx={{ mx: 1, pt: 2 }}>
      <Toast {...toast} />
      {
        showMasterList ? (
          <Masterlist onSave={saveGuestlist} toggleMasterlist={toggleMasterlist} />
        ) : (
          <Box>
            <div className={styles.label}>Event Type</div>
            <Grid container spacing={2} sx={{ mb: 4 }}>
              {/*Row 1 */}
              <Grid item xs={12}>
                <CustomSelect
                  id="type"
                  name='type'
                  className={styles.input}
                  sx={{ width: { xs: '100%', md: '90%', lg: '365px' } }}
                  size='small'
                  labelId="action"
                  value={eventInfo.type || ""}
                  onChange={handleTypeChange}
                  input={<OutlinedInput />}
                >
                  {eventTypes.length > 0 && eventTypes.map((item: any, index: any) => (
                    <MenuItem
                      key={index}
                      value={item.type}
                      sx={{
                        '&.Mui-selected': {
                          backgroundColor: 'var(--TLC-web-grey)',
                          '&:hover': {
                            backgroundColor: 'var(--TLC-web-grey)',
                          },
                        },
                      }}
                    >
                      {item.type}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </Grid>
              {/*Row 2 */}
              <Grid item xs={12}>
                <CustomTextField error={errors.title} helperText={errors.title ? "Requires title" : ""} sx={{ width: { xs: '100%', md: '90%', lg: '365px' }, mb: 4 }} value={eventInfo.title} onChange={handleChange} id="title" name="title" placeholder='Event Name' size='small' className={styles.input} />
              </Grid>
              {/*Row 3 */}
              <Grid item xs={12} className={styles.isflex}>
                <div className={styles.col}>
                  <CalendarMonthIcon sx={{ mt: 1, mr: 1 }} className={styles.icon} />
                  <InputLabel className={`${styles.inputLabel}`}><span className={styles.mandatory}>From: </span></InputLabel>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                    <MobileDatePicker
                      className={styles.input}
                      disablePast
                      value={startDate}
                      onChange={(newValue) => handleDateChange('startDate', newValue)}
                      renderInput={(params) => <CustomTextField sx={{ width: { xs: '100%', md: '90%', lg: '365px' }, maxWidth: '100%' }} size='small' {...params} />}
                      componentsProps={{
                        actionBar: {
                          sx: {
                            '& .MuiButtonBase-root': {
                              color: 'var(--TLC-gray)',
                            }
                          }
                        },
                      }}
                    />
                  </LocalizationProvider>
                </div>
                <div className={styles.col}>
                  <InputLabel className={styles.inputLabel}>To: <span className='optional'></span></InputLabel>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                    <MobileDatePicker
                      className={styles.input}
                      disablePast
                      value={endDate}
                      onChange={(newValue) => handleDateChange('endDate', newValue)}
                      renderInput={(params) => <CustomTextField sx={{ width: { xs: '100%', md: '90%', lg: '365px' }, maxWidth: '100%' }} size='small' {...params} />}
                      componentsProps={{
                        actionBar: {
                          sx: {
                            '& .MuiButtonBase-root': {
                              color: 'var(--TLC-gray)',
                            }
                          }
                        },
                      }}
                    />
                  </LocalizationProvider>
                </div>
              </Grid>
              {/*Row 4 */}
              <Grid item xs={12} className={styles.isflex}
                sx={{
                  mb: '20px'
                }}
              >
                <div className={styles.col}>
                  <AccessTimeIcon sx={{ mt: 1, mr: 1 }} className={styles.icon} />
                  <InputLabel className={`${styles.inputLabel} ${styles.inputMarginTop}`}><span className={styles.mandatory}>Starts: </span></InputLabel>
                  <CustomTextField
                    type='time'
                    value={startTime}
                    error={errors.startTime}
                    sx={{ width: { xs: '100%', md: '90%', lg: '365px' }, maxWidth: '100%' }} size='small'
                    onChange={(e) => handleTimeChange('startTime', e.target.value)}
                  />
                </div>
                <div className={styles.col}>
                  <InputLabel className={styles.inputLabel}>Ends: <span className='optional'></span></InputLabel>
                  <CustomTextField
                    type='time'
                    value={endTime}
                    sx={{ width: { xs: '100%', md: '90%', lg: '365px' }, maxWidth: '100%' }} size='small'
                    onChange={(e) => handleTimeChange('endTime', e.target.value)}
                  />
                </div>
              </Grid>
              {/*Row 5 */}
              <Grid item xs={12} className={styles.isflex}>
                <Grid container sx={{ display: 'flex', flexDirection: 'row' }} className={styles.addressCol}>
                  <Grid item xs={12} md={'auto'}><LocationIcon sx={{ mr: 1, ml: 0.5 }} className={styles.icon} /></Grid>
                  <Grid item xs={12} md={'auto'}><InputLabel className={`${styles.addressLabel} ${styles.inputMarginTop}`}><span className={styles.mandatory}>Address: </span></InputLabel></Grid>
                  <Grid item xs={12} md={'auto'} style={{ paddingLeft: 0 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <CustomTextField error={errors.address} sx={{ width: { xs: '100%', md: '90%', lg: '365px' }, pb: 2 }}
                        value={address.addline1 || ''} onChange={handleAddressChange}
                        id="addline1" name="addline1" placeholder='Address line 1' size='small' className={styles.input} />
                      <CustomTextField error={errors.address} sx={{ width: { xs: '100%', md: '90%', lg: '365px' }, pb: 2 }}
                        value={address.addline2 || ''} onChange={handleAddressChange}
                        id="addline2" name="addline2" placeholder='Address line 2' size='small' className={styles.input} />
                      <CustomTextField error={errors.address} sx={{ width: { xs: '100%', md: '90%', lg: '365px' }, pb: 4 }}
                        value={address.addpostalCode || ''} onChange={handleAddressChange}
                        id="addpostalCode" name="addpostalCode" placeholder='Postal Code' size='small' className={styles.input} />
                    </Box>
                  </Grid>
                </Grid>

                <div className={styles.col} style={{ flexDirection: 'column' }}>
                  <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
                    <Box style={{ width: 100 }} className={styles.centerIcon}>
                      <VideoIcon />
                    </Box>
                    <CustomSelect
                      id='livestream'
                      name='livestream'
                      className={styles.input}
                      sx={{ width: { xs: '100%', md: '90%', lg: '365px' } }}
                      size='small'
                      value={eventInfo.livestream}
                      onChange={handleLivestreamChange}
                    >
                      {livestreamAction.map((item) => (
                        <MenuItem
                          key={item}
                          value={item}
                          sx={{
                            '&.Mui-selected': {
                              backgroundColor: 'var(--TLC-web-grey)',
                              '&:hover': {
                                backgroundColor: 'var(--TLC-web-grey)',
                              },
                            },
                          }}
                        >
                          {item}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  </Box>
                  <div style={{ display: 'flex' }}>
                    <Box sx={{ width: { xs: 0, md: '100px' } }}></Box>
                    <CustomTextField error={errors.livestreamUrl} disabled={eventInfo.livestream.includes('Not') ? true : false} sx={{ width: { xs: '100%', md: '90%', lg: '365px' }, mt: 2 }} value={eventInfo.livestreamUrl} onChange={handleChange} id="livestreamUrl" name="livestreamUrl" placeholder='URL' size='small' className={styles.input} />
                  </div>
                </div>
              </Grid>
              {/*Row 6 */}
              <Grid item xs={12} className={styles.isflex}>
                <Box >
                  <InputLabel className={`${styles.inputLabel} ${styles.inputMarginTop}`}><span className={styles.mandatory}>Details:</span> </InputLabel>
                  <CustomTextField error={errors.details} helperText={errors.details ? "Requires details" : ""} sx={{ width: { xs: '100%', md: '90%', lg: '990px' }, mb: 4 }} value={eventInfo.details || ''} onChange={handleChange} required multiline rows={5} id="details" name="details" className={styles.input} />
                </Box>
              </Grid>
            </Grid>

            <div className={styles.label}>Add Guest</div>
            <Grid container spacing={2}>
              <Grid item xs={12} className={styles.isflex}>
                <Box>
                  <InputLabel>Select from existing guest list</InputLabel>
                  <Button className='btnSecondary' sx={{ width: { xs: 200, sm: 300 } }} onClick={handleShowMasterList}>View Guest List</Button>
                </Box>
              </Grid>
            </Grid>
            <GuestForm handleAddGuest={handleAddGuest} />

            {/* Guestlist */}
            {/* <Box className={styles.listContainer} sx={{padding: '5px'}}>
                {guestList.map((item) => {
                  return (
                    <ListItem key={item.email} className={styles.listItem}>
                      <ListItemText primary={item.firstName + ' ' + item.lastName} sx={{width: {xs:'200px'}}}/>
                      <ListItemText primary={item.email} sx={{width: {xs:'200px'}}}/>
                      <ListItemText primary={item.phoneNumber} sx={{width: {xs:'200px'}}}/>
                      <ListItemText primary={item.relationship} sx={{width: {xs:'200px'}}}/>
                      <Box>
                        <MuiLink className={styles.link} onClick={() => handleGuestDelete(item)}>Delete</MuiLink>
                      </Box>
                    </ListItem>
                  )
                })}  
              </Box>    */}
            <TableContainer className={styles.listContainer} sx={{ padding: '5px' }}>
              <StyledTableBody>
                {guestList.map((item) => {
                  return (
                    <TableRow key={item.email} className={styles.listItem}>
                      <StyledTableCell sx={{ minWidth: { xs: 200, sm: 300 } }}>{item.firstName + ' ' + item.lastName}</StyledTableCell>
                      <StyledTableCell sx={{ minWidth: { xs: 'auto', sm: 400 } }}>{item.email}</StyledTableCell>
                      <StyledTableCell sx={{ minWidth: { xs: 200 } }}>+{item.phoneNumber}</StyledTableCell>
                      <StyledTableCell sx={{ minWidth: { xs: 200 } }}>{item.relationship}</StyledTableCell>
                      <StyledTableCell>
                        <MuiLink className={styles.link} onClick={() => handleGuestDelete(item)}>Delete</MuiLink>
                      </StyledTableCell>
                    </TableRow>
                  )
                })}
              </StyledTableBody>

            </TableContainer>

            {
              props.type === 'account' ? (
                <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
                  <Grid item sx={{ mt: 2 }}>
                    <Button className='btnPrimary' loading={loading} sx={{ width: { xs: 250, sm: 300 }, mb: 4 }} onClick={handleSaveEvent}>Save Event</Button>
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
                  <Grid item sx={{ mt: 2 }}>
                    <Button className='btnSecondary' loading={loading} sx={{ width: { xs: 250, sm: 300 }, mb: 4 }} onClick={handleSaveEvent}>Save Event</Button>
                  </Grid>
                </Grid>
              )
            }
          </Box>
        )
      }


    </Box>
  )
  //}
}

export default CreateEvent